// Local modules.
import React from 'react';
// Styles.
import './App.css';

function StickerCollection(props) {
    return (
        <>
            {`功能製作中 ... 再等等哦！`}
        </>
    );
}

export default StickerCollection;
